import { useMainStore } from "~/store";

export default defineNuxtRouteMiddleware((to, from) => {
  const error = useError();
  const currentSite = useMainStore().currentSite;
  const route = useRoute();

  if (
    currentSite.value &&
    currentSite.value.settings &&
    !currentSite.value.settings.test_ride_inquiry &&
    route.path.includes("test-drive")
  ) {
    return error({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
  if (
    currentSite.value &&
    currentSite.value.settings &&
    !currentSite.value.settings.become_a_partner &&
    route.path.includes("become-a-dealer")
  ) {
    return error({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
  if (
    currentSite.value &&
    currentSite.value.settings &&
    !currentSite.value.settings.work_with_us &&
    route.path.includes("work-with-us")
  ) {
    return error({
      message: "This page could not be found.",
      statusCode: 404,
    });
  }
});